.hover-white:hover {
    color: white;
}

.footer{
  background-color: #0c6623;
  font-family: Arial, Helvetica, sans-serif;
  color: #fff;
  padding-top: 20px;
  font-weight: 400;

}

.footer-heading{
  color: #fff;
}

.footer-company-details{
  display: flex;
  justify-content: center;
  font-size: 12px;
  margin-right: 93px;
  margin-bottom: 6px;

}

.footer-company-address{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 30px;
  font-size: 12px;
  margin-bottom: 6px;

}

.footer-company-contact{
  display: inline;
  justify-content: center;
  align-items: stretch;
}

.footer-num{
  display: flex;
  flex-direction: row;
  margin-left: 35px;
  font-size: 12px;
}


@media (max-width: 768px)  {
  .footer-num{
    display: flex;
    flex-direction: row;
    font-size: 12px;
    justify-content: left;
    text-align: left;


  }
  .footer-company-details{
    display: flex;
    justify-content: left;
    font-size: 12px;
    margin-right: 8px;
    margin-bottom: 6px;
  
  }
  .footer-company-address{
    display: flex;
    justify-content: left;
    margin-right: 8px;
    font-size: 12px;
    margin-bottom: 6px;
    text-align: left;
  
  }
  
}