.search-form {
    width: 150px;
    display: flex;
    flex-grow: 1;
    align-items: center;
  }
  
  .search-input {
    width: 250px;
    margin-left: 10px;
    flex-grow: 1;
  }
  
  .search-button {
    margin-left: 10px;
  }
  .searchbar{
    width: 550px;
  }
  
  @media (max-width: 768px) {
    .search-form {
      flex-direction: column;
      align-items: stretch;
    }
  
    .search-input {
      width: 100%;
      margin-left: 0;
      margin-bottom: 10px;
    }
  
    .search-button {
      margin-left: 0;
      width: 100%;
    }
  }
  

  #header {
    background: #fff;
    transition: all 0.5s;
    z-index: 997;
    height: 86px;
    box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
}

.logo img{

  height: 44px;
  width: 230px;

}

.social-links a {
  margin-right: 20px;

}



.top-bar {
  background: white;
  height: 66px;
  color: black;
  padding: 0;
}

.contact-link {
  color: #00008b;
  margin-right: 14px;
  text-decoration: none;
}

.social-links .social-link {
  color: black;
  margin-left: 10px;
}

.social-link-bottom{
  color: rgb(247, 247, 247);
  margin-left: 10px;

}

.google-img{

  display: flex;
  flex-direction:row;
  align-items: center;
  justify-content: center;
  margin-left: 462px
}


.main-header {
  background: white;
  transition: all 0.5s;
  z-index: 997;
  padding: 10px 0;
}

.logo img {
  height: 40px;
  
}

.search-form {
  display: flex;
  flex-grow: 1;
}

.search-form .form-control {
  flex-grow: 1;
  border-radius: 5px 0 0 5px;
  border: 1px solid #ccc;
  margin: -10px;

}

.search-button {
  background-color: #ffffff;
  border-radius: 0 5px 5px 0;
  border: 1px solid rgb(197 197 197);
  padding: 6px 10px;
}

.toggle-button {
  background-color: #ffffff;
  color: rgb(0, 0, 0);
  border-radius: 5px;
  padding: 5px 20px;
  border: none;
  display: none;
  font-size: 1.5rem;
}

.menu {
  display: none;
  position: absolute;
  top: 90px;
  right: 10px;
  background: white;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  flex-direction: column;
  align-items: flex-start;
}

.menu.open {
  display: flex;
}

.auth-button {
  background-color: #007ee2;
  color: white;
  border-radius: 5px;
  padding: 3px 20px;
  border: none;
  margin-bottom: 0px;
  height: 33px;
}

.auth-button.signup {
  background-color: #f58f31;
}

.social-icons .social-link {
  color: black;
  margin-left: 10px;
}

.head{
  gap: 50px;
}

.butt{
  display: flex;
  flex-direction: row;
  gap: 35px;
  margin-left: 50px;
}


.login-btn{
  background-color:#007bff;
  color: #fff;
  font-size: 20px;
  border: none;
  border-radius: 5px;
  font-weight: 300;
  text-align: center;
  width: 100%;

}


.signup-btn{
  background-color:#007bff;
  color: #fff;
  font-size: 20px;
  border: none;
  border-radius: 5px;
  font-weight: 300;
  text-align: center;
  width: 100%;

}

.login-redirect{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
  /* gap: 292px; */
}

.forget-pass{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

}

@media (max-width: 768px) {
  .social-links {
    display: none;
  }

  .toggle-button {
    display: block;
  }

  .menu {
    width: 100%;
    right: 0;
  }

  .auth-button {
    display: block;
    margin-bottom: 10px;
  }

  .social-icons {
    display: flex;
    width: 100%;
    justify-content: space-around;
  }
  .logo img {
    height: 40px;
    
  }
  .head{
    gap: 10px;
  }

  .search-form {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
    margin-left: 50px;
  }
  
  .form-controls {
    width: 280px;
    padding: 7px;
    margin-left: 87px;
    margin-bottom: 10px;
    border-radius: 5px 0px 0px 5px;
    border: 1px solid rgb(82, 81, 81);
  
  }
  .search-button {
    background-color: #ffffff;
    border-radius: 0 5px 5px 0;
    border: 1px solid rgb(82, 81, 81);
    padding: 7px 10px;
    margin-bottom: 10px;
  }
  
}

