.package-card {
  background: white;
  color: black;
  border-radius: 10px;
  padding: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  text-align: center;
}

.package-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.package-image-container {
  background: #ffffff;
  border-radius: 10px 10px 0 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  height: 200px; /* Adjust height as needed */
}

.package-image {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  /* border-radius: 10px 10px 0 0; */
}

.package-name {
  font-size: 16px;
  font-weight: 600;
  margin-top: 10px;
  text-decoration: dotted;
}

/* Style the slider container */
.slider-inner {
  position: relative;
}

/* Customize the arrows */
.slick-prev,
.slick-next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: #ddd;
  border: none;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 1;
}

.slick-prev {
  left: -40px; /* Adjust this value as needed */
}

.slick-next {
  right: -40px; /* Adjust this value as needed */
}

.slick-prev:before,
.slick-next:before {
  font-size: 20px;
  color: #333;
}

.slick-prev:hover,
.slick-next:hover {
  background: #bbb;
}
.react-flags-select .flag-select {
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.react-flags-select .flag-select:focus {
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.blog-card {
  overflow: hidden; /* Ensure the image stays within the card bounds */
}

.blog-card-img {
  transition: transform 0.3s ease-in-out;
}

.blog-card:hover .blog-card-img {
  transform: translateY(-10px);
}

.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}

.popup {
  background: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.popup button {
  margin: 10px;
  padding: 10px 20px;
  border: none;
  background-color: #5bc0de;
  color: white;
  cursor: pointer;
}

.popup button:hover {
  background-color: #46a5c1;
}

a {
  text-decoration: none !important;
}

.related-products {
  text-align: center;
  background-color: beige;
  height: 300px;
}
.input-group {
  position: relative;
}

.input-icon {
  position: absolute;
  right: 30px;
  padding-bottom: 40px;
  transform: translateY(-50%);
  cursor: pointer;
}

.h-entryblog {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.h-entryblog img {
  width: 100%;
  height: 300px;
  object-fit: contain;
}
.h-entry {
  overflow: hidden; /* Ensures the image stays within the container bounds */
  height: auto;
}

.h-entry img {
  width: 100%;
  height: 170px;

  object-fit: cover;
  transition: transform 0.3s ease; /* Smooth transition for zoom effect */
}

.h-entry img:hover {
  transform: scale(1.1); /* Slight zoom on hover */
}
#blog-related {
  width: 310px;
}

.h-text {
  height: 200px;
  display: flex; /* Use flexbox to arrange items inside */
  flex-direction: column; /* Stack child elements vertically */
  justify-content: space-between;
  text-align: start;
}
.h-text h5 {
  margin-bottom: 5px;
  color: black;
}
.h-text h5:hover {
  color: green;
}

#blog-section .pagination {
  padding-left: 10px;
}
#blogdetailcategory a {
  font-weight: 500;

  color: green;

  border: none;
}
#secLearnmore a {
  font-weight: 500;
  background-color: #0ba60b;
  color: white;
  padding: 3px;
  border: none;
}
#secLearnmore a:hover {
  background-color: white; /* Change to a darker green on hover */
  color: #0ba60b; /* Optional: change text color on hover */
  cursor: pointer;
  border: 1px solid #0ba60b;
}
.blog-category {
  color: green;
  font-weight: 500;
  margin: 0;
}

.blog-category a:hover {
  background-color: white; /* Change to a darker green on hover */
  color: green; /* Optional: change text color on hover */
  cursor: pointer;
  border: 1px solid green;
}
.h-text .date {
  margin-bottom: 5px;
  font-family: "Italiana", serif;
}
.h-text .Learnmore a {
  font-weight: 500;
  background-color: #0ba60b;
  color: white;
  padding: 3px;
  border: none;
}

.h-text .Learnmore a:hover {
  background-color: white; /* Change to a darker green on hover */
  color: #0ba60b; /* Optional: change text color on hover */
  cursor: pointer;
  border: 1px solid #0ba60b;
}
.h-text-secondary {
  height: 240px;
  display: flex; /* Use flexbox to arrange items inside */
  flex-direction: column; /* Stack child elements vertically */
  justify-content: space-between;
  text-align: start;
}
.h-text-secondary .Learnmore {
  font-weight: 500;
}

#blog-section {
  width: 100%;
}

/* SpinnerSquare.css */
.spinner-square {
  display: flex;
  flex-direction: row;
  width: 90px;
  height: 120px;
}

.spinner-square > .square {
  width: 17px;
  height: 80px;
  margin: auto auto;
  border-radius: 4px;
}

.square-1 {
  animation: square-anim 1200ms cubic-bezier(0.445, 0.05, 0.55, 0.95) 0s
    infinite;
}

.square-2 {
  animation: square-anim 1200ms cubic-bezier(0.445, 0.05, 0.55, 0.95) 200ms
    infinite;
}

.square-3 {
  animation: square-anim 1200ms cubic-bezier(0.445, 0.05, 0.55, 0.95) 400ms
    infinite;
}

@keyframes square-anim {
  0% {
    height: 80px;
    background-color: #98d602;
  }
  20% {
    height: 80px;
  }
  40% {
    height: 120px;
    background-color: #84b102;
  }
  80% {
    height: 80px;
  }
  100% {
    height: 80px;
    background-color: #98d602;
  }
}

.spinermain {
  width: 100%;
  height: 120vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 768px) {
  .product-detail {
    width: 100px;
  }
}

/* -------------------------------------forgetpassword------------------------------- */

.Forgetpassword form {
  background: #fff;
  padding: 1em 2em 2em;
  max-width: 400px;
  margin: 50px auto 50px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  border-radius: 5px;
}

.Forgetpassword p {
  position: relative;
}

.Forgetpassword label {
  display: block;
  font-size: 22px;
  margin: 0 0 0.5em;
  color: #333;
}

.Forgetpassword input {
  display: block;
  box-sizing: border-box;
  width: 100%;
  outline: none;
}

.Forgetpassword input[type="text"],
.Forgetpassword input[type="password"] {
  background: #f5f5f5;
  border: 1px solid #e5e5e5;
  font-size: 1.3em;
  /* padding: 0.8em 0.5em; */
  border-radius: 5px;
}

.Forgetpassword input[type="text"]:focus,
.Forgetpassword input[type="password"]:focus {
  background: #fff;
}

.Forgetpassword span {
  border-radius: 5px;
  display: block;
  font-size: 12px;
  text-align: center;
  position: absolute;
  background: #2f558e;
  left: 105%;
  top: 50px;
  width: 250px;
  padding: 7px 10px;
  color: #fff;
}

.Forgetpassword span:after {
  right: 100%;
  top: 50%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: rgba(136, 183, 213, 0);
  border-right-color: #2f558e;
  border-width: 8px;
  margin-top: -8px;
}

.Forgetpassword input[type="submit"] {
  background: #2f558e;
  box-shadow: 0 3px 0 0 #1d3c6a;
  border-radius: 5px;
  border: none;
  color: #fff;
  cursor: pointer;
  display: block;
  font-size: 21px;
  line-height: 1.6em;
  margin: 2em 0 0;
  outline: none;

  text-shadow: 0 1px #68b25b;
}

.Forgetpassword input[type="submit"]:disabled {
  background: #a0a0a0;
  box-shadow: none;
  cursor: not-allowed;
}
#makecallbtn {
  padding: 11px 30px;
}
#GetQuotebtn {
  padding: 7px 30px;
}
#ChatNow {
  padding: 7px 25px;
}

@media only screen and (max-width: 768px) {
  #makecallbtn {
    padding: 12px 7px;
  }
  #GetQuotebtn {
    padding: 3px 8px;
  }
  #ChatNow {
    padding: 3px 0px;
  }
}

/* Careers.css */
.custom-modal {
  max-width: 100%; /* Adjust as needed */
  padding: 20px;
}

.custom-modal-dialog {
  max-width: 800px; /* Adjust as needed */
}

.formbold-mb-3 {
  margin-bottom: 15px;
}

.formbold-main-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 48px;
}

.formbold-form-wrapper {
  margin: 0 auto;
  max-width: 700px;
  width: 100%;
  background: #b5f3d8;
  padding: 40px;
}

.formbold-img {
  display: block;
  margin: 0 auto 45px;
}

.formbold-input-wrapp > div {
  display: flex;
  gap: 20px;
}

.formbold-input-flex {
  display: flex;
  gap: 20px;
  margin-bottom: 15px;
}
.formbold-input-flex > div {
  width: 100%;
}
.formbold-form-input {
  width: 100%;
  padding: 13px 22px;
  border-radius: 5px;
  border: 1px solid #dde3ec;
  background: #ffffff;
  font-weight: 500;
  font-size: 16px;
  color: #536387;
  outline: none;
  resize: none;
}
.formbold-form-input::placeholder,
select.formbold-form-input,
.formbold-form-input[type="date"]::-webkit-datetime-edit-text,
.formbold-form-input[type="date"]::-webkit-datetime-edit-month-field,
.formbold-form-input[type="date"]::-webkit-datetime-edit-day-field,
.formbold-form-input[type="date"]::-webkit-datetime-edit-year-field {
  color: rgba(83, 99, 135, 0.5);
}

.formbold-form-input:focus {
  border-color: #6a64f1;
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.05);
}
.formbold-form-label {
  color: #07074d;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  display: block;
  margin-bottom: 10px;
}

.formbold-form-file-flex {
  display: flex;
  align-items: center;
  gap: 20px;
}
.formbold-form-file-flex .formbold-form-label {
  margin-bottom: 0;
}
.formbold-form-file {
  font-size: 14px;
  line-height: 24px;
  color: #536387;
}
.formbold-form-file::-webkit-file-upload-button {
  display: none;
}
.formbold-form-file:before {
  content: "Upload file";
  display: inline-block;
  background: #eeeeee;
  border: 0.5px solid #fbfbfb;
  box-shadow: inset 0px 0px 2px rgba(0, 0, 0, 0.25);
  border-radius: 3px;
  padding: 3px 12px;
  outline: none;
  white-space: nowrap;
  -webkit-user-select: none;
  cursor: pointer;
  color: #637381;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  margin-right: 10px;
}

.formbold-btn {
  text-align: center;
  width: 100%;
  font-size: 16px;
  border-radius: 5px;
  padding: 14px 25px;
  border: none;
  font-weight: 500;
  background-color: #6a64f1;
  color: white;
  cursor: pointer;
  margin-top: 25px;
}
.formbold-btn:hover {
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.05);
}

.formbold-w-45 {
  width: 45%;
}
.breadcrumbsingle {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; /* Optional: adds "..." for overflowed text */
}
.disclaimer {
  font-style: italic;
  background-color: #f8f8f8;
  padding: 15px;
  border: 1px solid #e2e2e2;
  border-radius: 5px;
  font-size: 14px;
  color: #555;
}

.disclaimer strong {
  font-weight: bold;
}
